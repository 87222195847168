


























































































































































































import Vue from 'vue'
import { ApiResponse, Document, Contact, FormContact } from '@/interfaces'
import { mapState, mapActions } from 'vuex'
import SendMailForm from '@/components/SendMailForm.vue'
import EditDocumentForm from '@/components/EditDocumentForm.vue'

interface SendMailData {
  contacts: FormContact[]
  customText?: string
  structure?: number | null
  chosenModel?: number | null
}

interface DocumentType {
  label?: string
  value?: string
}

export default Vue.extend({
  components: { SendMailForm, EditDocumentForm },
  props: {
    documents: {
      type: Array,
      required: false,
      default: [] as Document[],
    },
    parentType: {
      type: String,
      required: true,
      default: '',
    },
    defaultSendContact: {
      type: Object as () => Contact,
      required: false,
      default: null,
    },
    debiteurSendContact: {
      type: Object as () => Contact,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
    parentId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      showSendDocumentModal: false,
      sendDocumentType: '', // Permet de constomiser le composant d'envoi de mail en fonction du type d'envoi
      documentToSend: {} as Document,
      sendMailData: {} as SendMailData,
      documentToEdit: {} as Document | null,
      documentIndex: -1,
      showEditDocumentModal: false,
      urlParentType: '',
      documentTypes: [] as DocumentType[],
      searchFilter: '',
      typeFilter: '',
      addons: null,
      filteredItems: [] as Document[] | any,
      selectedIds: [] as number[],
    }
  },
  computed: {
    ...mapState(['user']),
    userRestriction(): boolean {
      return this.user?.roles.includes('RESTRICTION_USER')
    },
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.documentTypes = newVal.client.config.documentTypes
        this.addons = newVal.client?.addons ?? 0
      }
    },
    activeTab(newVal) {
      if (newVal === 'documents' /*&& this.documents.length === 0*/) {
        this.urlParentType = this.parentType
        if (this.parentType === 'requisitionItem') {
          this.urlParentType = 'requisitionitems'
        }
        //this.getDocuments()
      }
    },
    parentType(newVal) {
      this.urlParentType = newVal
      if (newVal === 'requisitionItem') {
        this.urlParentType = 'requisitionitems'
      }
    },
    documents(newVal) {
      this.filteredItems = newVal
      this.filterList()
    },
  },
  mounted() {
    if (this.activeTab && this.activeTab === 'documents' && this.parentType && this.parentId) {
      this.urlParentType = this.parentType
      if (this.parentType === 'requisitionItem') {
        this.urlParentType = 'requisitionitems'
      }
      //this.getDocuments()
    }
    if (this.user) {
      this.addons = this.user.client?.addons ?? 0
      this.documentTypes = this.user.client.config.documentTypes
    }
  },
  methods: {
    addonTest(code: number) {
      // La fonction est appelée en :key du tableau pour forcer le rechargement complete du tableau par element
      // en car de changerment du codeAddon (watch)
      let test = false
      if (this.addons == null) {
        test = false
      }
      else {
        test = (BigInt(code) & BigInt(this.addons)) != BigInt(0)
      }
      return test
    },
    download(format: string, token: string) {
      let url = ''

      if (format == 'pdf') {
        url = `${process.env.VUE_APP_API_URL}/document/${token}/download?contentd=inline`
      } else {
        url = `${process.env.VUE_APP_API_URL}/document/${token}/download`
      }

      window.open(url, '_blank')
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
      } else {
        return '-'
      }
    },
    formatSize(size: string): string {
      const s = parseFloat(size)
      const kb = s * 1000

      return `${kb} ko`
    },
    getFormat(format: string): string {
      switch (format) {
        case 'docx':
          return 'DOCX'

        case 'pdf':
          return 'PDF'

        default:
          return 'Autre'
      }
    },
    getType(document: Document): string {
      return document.labelType ?? ''
    },
    getStructureName(document: Document) {
      if (document.structure) {
        return ` (${document.structure.name})` ?? ''
      }
      return ''
    },
    getCreatedBy(document: Document) {
      if (document.createdBy) {
        return `${document.createdBy.formName}` ?? '-'
      } else {
        return '-'
      }
    },
    toggleSign(document: Document) {
      // popup de confirmation d'écriture dans le livre de police
      if (document.type === 'vente' && document.signed) {
        this.$confirm(
          "En signant ce mandat, les lots vont être écrits dans le livre de police numérique (s'il n'y sont pas déjà)",
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        )
          .then(() => {
            this.$api
              .post(`/document/${document.id}/togglesign`)
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                // Updating document
                document.signed = apiResponse.data.document.signed
                // Mise à jour des lots dans le cas d'un mandat de vente
                this.$emit('updateRequisitionItems', apiResponse.data.items)
              })
              .catch(() => {
                // Reverting status
                document.signed = !document.signed
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    'Une erreur est survenue dans la signature du document. Veuillez nous excuser pour la gêne occasionnée.',
                })
              })
          })
          .catch(() => {
            // Reverting status
            document.signed = !document.signed
            this.$notify({
              type: 'warning',
              title: 'Annulation',
              message: 'Signature annulée.',
            })
          })
      } else {
        this.$api
          .post(`/document/${document.id}/togglesign`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            // Updating document
            document.signed = apiResponse.data.document.signed
          })
          .catch(() => {
            // Reverting status
            document.signed = !document.signed
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                'Une erreur est survenue dans la signature du document. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
      }
    },
    deleteDocument(id: number) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir effectuer cette suppression ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        const data = {
          ids: this.selectedIds,
        }    
        if (id) {
          data.ids = [ id ]
        }            
        this.$api
          //.delete(`/document/${id}/${this.parentType}`)
          .post(`/document/delete`, data)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            this.$emit('refreshDocuments', apiResponse.data)
          })
          .catch((error) => {
            if (error.response && error.response?.status == 500) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      })
    },
    openSendDocument(document: Document, type: string) {
      this.showSendDocumentModal = true
      this.documentToSend = document
      this.sendDocumentType = type
    },
    closeSendDocument() {
      this.showSendDocumentModal = false
      this.documentToSend = {}
      this.sendDocumentType = ''
    },
    submit(sendMailData: SendMailData) {
      this.sendMailData = sendMailData
      this.sendDocument()
    },
    sendToSign(document: Document) {
      let message = `Vous vous apprêtez à envoyer un document pour signature à ${this.defaultSendContact.formName}. Êtes-vous sûr(e) ?`
      this.$confirm(message, 'Confirmation', {
        confirmButtonText: 'Envoyer',
        cancelButtonText: 'Annuler',
        type: 'warning',
      }).then(() => {
        this.$api
          .get(`/yousign/${document.accessToken}/to-sign`, {
            params: {
              contact: this.defaultSendContact?.id,
            },
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'La demande de signature du document a bien été envoyée.',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      })
    },
    sendDocument() {
      // let successMessage = 'Le document '
      // if (document.title) {
      //   successMessage += document.title + ' '
      // }
      // successMessage += 'a été envoyé.'

      const id = this.documentToSend?.id ?? 0

      const loading = this.$loading({
        target: '#container',
        text: 'Chargement des données...',
      })

      const data = {
        ...this.sendMailData,
        //type: this.parentType,
        ids: this.selectedIds,
      }
      if (id) {
        data.ids = [ id ]
      }

      let success = false
      this.$api
        .post(`/document/send`, data)
        .then(() => {
          // this.$notify({
          //   type: 'success',
          //   title: 'Document envoyé !',
          //   message: successMessage,
          // })
          success = true
        })
        .catch((error) => {
          // Si plantage on gère en front, les autres messages sont gérés par l'api
          if (error.response && error.response?.status == 500) {
            const apiResponse = error.response.data as ApiResponse
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          loading.close()
          if (success) {
            this.closeSendDocument()
          }
        })
    },
    convertToPdf(document: Document, index: number) {
      this.$api
        .post(`/document/${document.id}/pdf`, {
          page: this.parentType === 'contact' ? 'contactDocuments' : null,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès !',
          })
          // modify this doc
          this.documents.splice(index, 1, apiResponse.data)
          //this.$emit('refreshDocuments', apiResponse.data)
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    updateDoc(document: Document) {
      // On pousse le doc dans le formulaire d'upload
      this.$emit('updateDoc', document)
      // refresh
      //this.getDocuments()
    },
    displayForSend(document: Document) {
      return document.format === 'pdf'
    },
    // displayForSign(document: Document) {
    //   return document.format === 'pdf' && document.contact?.id
    // },
    openEditDocument(document: Document, index: number) {
      this.showEditDocumentModal = true
      this.documentToEdit = document
      this.documentIndex = index
    },
    closeEditDocument() {
      this.showEditDocumentModal = false
      this.documentToEdit = null
      this.documentIndex = -1
    },
    submitEditDocument(document: Document) {
      this.busy = true
      this.$api
        .put(`/document/${document.id}`, {
          type: document.type ?? '',
          title: document.title ?? '',
          reference: document.reference ?? '',
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.documents.splice(this.documentIndex, 1, apiResponse.data)
          // this.$notify({
          //   type: 'success',
          //   title: 'Succès',
          //   message: 'Document modifié avec succès !',
          // })

          this.$emit('refreshItem')
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          this.closeEditDocument()
        })
    },
    filterList() {
      this.filteredItems = this.documents
      if (this.searchFilter && this.searchFilter !== '') {
        this.filteredItems = this.filteredItems.filter((item: Document) =>
          item.title?.toLowerCase().includes(this.searchFilter?.toLowerCase() ?? '')
        )
      }
      if (this.typeFilter && this.typeFilter !== '') {
        this.filteredItems = this.filteredItems.filter((item: Document) => item.type == this.typeFilter)
      }
    },
    handleSelectionChange(selectedItems: Document[]) {
      this.selectedIds = []
      selectedItems.forEach((selectedItem) => {
        if (selectedItem?.id) {
          this.selectedIds?.push(selectedItem.id)
        }
      })
    },
    getSummary() {
      const count = this.selectedIds?.length ?? 0
      const countDocuments = this.filteredItems?.length ?? 0
      let summary = countDocuments + ' document(s)'
      if (count) {
        summary += ', ' + count + ' sélectionné(s)'
      }
      return summary
    },
    handleItemCommand(command: string) {
      if (command == 'massSend') {
        this.showSendDocumentModal = true
        this.documentToSend = {}
        this.sendDocumentType = 'doc'
      }
      if (command == 'massDelete') {
        // 0 = dummy, this will delete selectedIds
        this.deleteDocument(0)
      }
    },
  },
})
